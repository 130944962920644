<template>
  <div class="ym-bg-primary flex pvflex flex-row justify-between">
    <PVButton icon="material-icons menu" class="bg-transparent text-black" @click="toggleDrawer"/>
    <div v-if="authentication.isLoggedIn" class="justify-end">
      <PVButton icon="material-icons exit_to_app" class="bg-primary text-primary-contrast p-button-text uppercase nowrap" severity="primary" label="Log Out" @click="authentication.logout()"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: "ToolBar",
});
</script>
