<template>
  <div v-if="styleOLD" class="vue3">
    <PVToast position="top-right" style="top:4rem; width:20rem; height:3rem;background:transparent"/>
    <ToolBar/>
    <div class="flex pvflex flex-row flex-nowrap">
      <NavigationDrawer v-if="preferences.isDrawerOpen" :styleNEW="false"/>
      <div class="m-2 w-full" xxstyle="max-width: 2560px">
        <router-view/>
      </div>
    </div>
  </div>
  <div v-else>
    <PVMenubar v-if="false" :model="menus" class="ym-mainmenu">
      <template #end>
        <PVButton label="Log Out" icon="pi pi-sign-out" @click="authentication.logout()"/>
      </template>
    </PVMenubar>
    <PVMegaMenu v-if="false" :model="[{label:'Home',icon:'pi pi-home'}]" orientation="vertical"/>
    <PVToast position="top-right" @close="done($event)" @life-end="done($event)"/>
    <NavigationToolBar id="navigation-toolbar" style="background-color: var(--primary-color) !important;" :styleNEW="true"/>
    <div class="pt-10" style="max-width: 2560px">
      <RouterView/>
    </div>
  </div>    
</template>

<script>
import NavigationDrawer from "../components/common/navigation/navigation/NavigationDrawer.vue";
import NavigationToolBar from "../components/common/navigation/NavigationToolBar.vue";
import PVMegaMenu from 'primevue/megamenu';
import PVMenubar from 'primevue/menubar';
import PVToast from 'primevue/toast';
import ToolBar from "../components/common/navigation/navigation/ToolBar.vue";
import { useVersioningStore } from "@/store/versioning.ts";

export default {
  name: 'App',
  components: { NavigationToolBar, PVMenubar, PVMegaMenu, PVToast, NavigationDrawer, ToolBar },
  props: {
    styleOLD: {
      default: true,
    }
  },
  computed:{
    menus() {
      return [
        { label:'Home',icon:'pi pi-home',
          items:[
            { label:'User',icon:'pi pi-home' },
            { label:'Patients',icon:'pi pi-home' },
            { label:'Tags',icon:'pi pi-home' },
            { label:'Monitoring',icon:'pi pi-home' },
            { label:'Monitoring(Beta)',icon:'pi pi-home' },
            { label:'Test Features',icon:'pi pi-home' },
            { label:'App Users',icon:'pi pi-home' },
            { label:'Automated Reports',icon:'pi pi-home' },
            { label:'Dark Mode',icon:'pi pi-home' },
            { label:'Browser Timezone',icon:'pi pi-home' },
            { label:'Change Permissions',icon:'pi pi-home' },
            { label:'Privacy Policy',icon:'pi pi-home' },
            { label:'Terms & Conditions',icon:'pi pi-home' },
          ],
        }
      ];
    },
    updateAvailable() { const vers = useVersioningStore();  return vers.updateAvailable; },
  },
  watch:{
    isDark() {
      console.log("DARK",this.isDark);
      document.documentElement.classList.toggle('ym-dark');
    },
    updateAvailable() {
      if (this.updateAvailable) {
        this.$toast.add({ severity: "info", details:"An update is available - please refresh" });
      }
    },
    global_messages() {
      this.global_messages?.forEach((m) => {
        this.$toast.add(m);
      });
      if (this.global_messages?.length > 0) this.clearMessages();
    },
    reload() {
      window.location.reload();
    }
  },
}
</script>

<style>
  #app > .ym-mainmenu > .p-menubar {
  margin-top:64px !important;
  background:rgb(var(--v-theme-primary)) !important;
  background:green !important;
  height:64px;
  }

  #app > .ym-mainmenu > .p-menubar > div.p-menubar-end {
  margin-left:auto;
  }
  
  #app > .ym-mainmenu > .p-menubar > ul > li > .p-menuitem-content > .p-menuitem-link > .p-menuitem-text,
  #app > .ym-mainmenu > .p-menubar > ul > li > .p-menuitem-content > .p-menuitem-link > .p-menuitem-icon {
    color: white !important;
  }
</style>

<style scoped>

@media print {
    #error-snackbar, #success-snackbar, #update-snackbar, #navigation-toolbar, #navigation-toolbar {
        display: none;
        width: 0px;
    }
}

</style>
