<template>
  <div class="flex flex-row justify-between flex-wrap">
    <NavigationDrawer :styleNEW="styleNEW"/>
    <div>
      <PVButton label="Log Out" icon="material-icons exit_to_app" @click="authenticationStore.logout()"/>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavigationDrawer from "./navigation/NavigationDrawer.vue";
import { useAuthStore } from "@/store/authentication.ts";

export default defineComponent({
  name: 'NavigationToolBar',
  components: { NavigationDrawer },
  props: {
    styleNEW:{
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const authenticationStore = useAuthStore();
    return { authenticationStore };
  },
});
</script>

<style scoped>

</style>
