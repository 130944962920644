interface AsyncInterval {
  id: ReturnType<typeof setInterval>|null;
  run: boolean;
};

type AsyncIntervalCallback = () => Promise<void>;

const asyncIntervals: AsyncInterval[] = [];

const runAsyncInterval = async (cb: AsyncIntervalCallback, interval: number, intervalIndex: number) => {
  await cb();
  if (asyncIntervals[intervalIndex].run) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    asyncIntervals[intervalIndex].id = setTimeout(() => runAsyncInterval(cb, interval, intervalIndex), interval);
  }
};

export const setAsyncInterval = (cb: AsyncIntervalCallback, interval: number): number => {
  if (cb && typeof cb === "function") {
    const intervalIndex = asyncIntervals.length;
    asyncIntervals.push({ run: true, id: null });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    runAsyncInterval(cb, interval, intervalIndex);
    return intervalIndex;
  } else {
    throw new Error('Callback must be a function');
  }
};

export const clearAsyncInterval = (intervalIndex: number): void => {
  if (asyncIntervals[intervalIndex].run && asyncIntervals[intervalIndex].id) {
    clearTimeout(asyncIntervals[intervalIndex].id);
    asyncIntervals[intervalIndex].run = false;
  }
};
