<template>
  <div v-if="styleNEW">
    <PVButton icon="material-icons menu" @click="togglemenu($event)"/>
    <PVMenu ref="menu" :model="items" popup>
      <template #item="{ item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a :href="href" v-bind="props.action" @click="navigate">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </router-link>
        <div v-else-if="item.toggleDM">
          {{ item.label }}
          <PVInputSwitch :value="dark" @change="preferences.toggleDarkMode(!isDark)"/>
        </div>
        <div v-else-if="item.toggleBT">
          {{ item.label }}
          <PVInputSwitch :value="timezone" @change="preferences.updateTimezone(!preferences.timezone.useBrowserTime)"/>
        </div>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
          <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
      </template>
    </PVMenu>
    <SuperPermissionsToggleDialog v-if="permissions != null" v-model:visible="permDialog"/>
  </div>
  <div v-else :value="isDrawerOpen" app class="flex pvflex flex-col flex-none" style="max-width:16rem; height:min-content" @xxinput="toggleDrawer">
    <div v-if="!firstLoad" class="flex pvflex flex-col gap-0 p-2 m-2" style="color:var(--p-form-field-color)">
      <div v-for="(option, key) in options" :key="key" :class="'flex pvflex flex-row flex-nowrap p-2 xxtext-black ' + ($route.path == option.route ? 'ym-bg-secondary':'')">
        <i :class="'material-icons '+option.icon" style="width:2rem; color:var(--p-form-field-color); xxxcolor:rgba(0,0,0,.54);"/>
        <router-link :to="option.route" @click.stop> {{ option.title }} </router-link>
      </div>
      <PVDivider/>
      <div class="flex pvflex flex-row flex-nowrap gap-2">
        <i class="material-icons wb_sunny" style="width:2rem"/>
        <span class="flex-1">Dark Mode</span>
        <PVInputSwitch style="height:1.25rem" :value="isDark" @change="preferences.toggleDarkMode(!isDark)"/>
      </div>
      <div v-if="authentication.access.superStatus" class="flex pvflex flex-row flex-nowrap gap-2">
        <i class="material-icons alarm" style="width:2rem"/>
        <span class="flex-1">Browser Timezone</span>
        <PVInputSwitch style="height:1.25rem" :value="timezone" @change="toggleBrowserTimeZone"/>
      </div>
      <div v-if="authentication.access.superStatus">
        <PVDivider/>
        <PVButton raised class="uppercase nowrap" label="Change Permissions" severity="secondary" @click="permDialog = true"/>
        <SuperPermissionsToggleDialog v-if="permissions != null" v-model:visible="permDialog" />
      </div>
      <div class="flex pvflex flex-col px-2 text-center pt-2">
        <a href="/privacy_policy">
          Privacy Policy
        </a>
        <a href="/terms_conditions">
          Terms & Conditions
        </a>
      </div>
    </div>
  </div>
</template>

<script xxlang="ts"> // TODO
import { defineComponent } from 'vue';

import SuperPermissionsToggleDialog from "./SuperPermissionsToggleDialog.vue";
import _ from 'lodash';
import PVDivider from 'primevue/divider';
import PVMenu from 'primevue/menu';

export default defineComponent({
  name: "NavigationDrawer",
  components: { SuperPermissionsToggleDialog, PVDivider, PVMenu },
  props: {
    styleNEW:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: null,
      firstLoad: true,
      permDialog: false,      
    }
  },
  computed: {
    timezone() {
      return this.preferences.timezone != null ? this.preferences.timezone.useBrowserTime : null;
    },
    dark() {
      return this.isDark;
    },
    superAdmin() {
      return this.authentication.access.superAdmin;
    },
    permissions() {
      return this.authentication.access.permissions;
    },
    
    items() {
      let items = [];
      if (!this.options) return [];
      this.options.forEach((option) => {
        items.push({ label:option.title, icon:('material-icons ' + option.icon), route: option.route, command:()=>{ this.$router.push(option.route); } });
      });
      
      items.push({ label:'' });
      items.push({
        label:'Settings',
        icon: 'pi pi-cog',
        items:[
          { label:'Dark Mode', toggleDM:true },
          { label:'Browser Timezone', toggleBT:true },
          { label:'Change Permissions', command:()=>{ this.permDialog = true; } },
        ] });
      items.push({ label:'Privacy Policy', url:'/privacy_policy' });
      items.push({ label:'Terms & Conditions', url:'/terms_conditions' });
      
      return items;
      
      /*
        return [
        {
        label: '...',
        icon: 'material-icons menu',
        items: items,
        },
        ];*/
    }
  },
  watch:{
    authentication() { this.storeUpdate(); },
  },
  beforeMount() {
    this.storeUpdate();
  },
  methods: {
    togglemenu(e) {
      this.$refs.menu.toggle(e);
    },
    
    unauthorisedOptions() {
      return [
        {
          icon: 'email',
          title: 'Login',
          route: '/login',
        }
      ];
    },
    defaultOptions() {
      return [
        {
          icon: 'dashboard',
          title: 'Home',
          route: '/'
        },
        {
          icon: 'person',
          title: 'User',
          route: '/user'
        }
      ];
    },
    storeUpdate: _.debounce(function () {
      this.checkOptions();
    }, 200),
    checkOptions() {
      if (this.authentication != null && this.authentication.isLoggedIn && this.permissions != null) {
        let options = this.defaultOptions();
        // Now we need to add checks for each permission
        if (this.permissions.patients.view) {
          options = options.concat([
            {
              icon: 'people',
              title: 'Patients',
              route: '/patients',
              exact: false
            }
          ]);
        }
        if (this.permissions.tags.view) {
          options = options.concat([
            {
              icon: 'devices',
              title: 'Tags',
              route: '/tags',
              exact: false
            }
          ]);
        }
        if (this.permissions.companies.view) {
          options = options.concat([
            {
              icon: 'business',
              title: 'Companies',
              route: '/companies',
              exact: false
            },
          ]);
        }
        if (this.permissions.monitoring.view) {
          options = options.concat([
            {
              icon: 'view_compact',
              title: 'Monitoring',
              route: '/monitoring'
            },
            {
              icon: 'view_compact',
              title: 'Monitoring (Tech)',
              route: '/monitoring2'
            },
            {
              icon: 'view_compact',
              title: 'Monitoring (Patient)',
              route: '/patientmonitoring'
            }
          ]);
        }
        if (this.authentication.access.superAdmin) {
          options = options.concat([
            {
              icon: 'new_releases',
              title: 'Test Features',
              route: '/testing',
              exact: false
            },
            {
              icon: 'smartphone',
              title: 'App Users',
              route: '/app/users',
              exact: false
            },
            {
              icon: 'auto_stories',
              title: 'Automated Reports',
              route: '/automated-reports',
              exact: false
            }
          ]);
        }
        this.options = options;
      } else {
        this.options = this.unauthorisedOptions();
      }
      this.firstLoad = false;
    }
  },
});
</script>

<style scoped>

a {
    text-decoration: none !important;
}

a:visited {
/*    color:var(--p-secondary-color) !important;*/
    color:var(--p-form-field-color) !important;
}
a:link {
    color:var(--p-form-field-color) !important;
}

</style>
