import { defineStore } from 'pinia'
import axios, { AxiosError, isAxiosError } from 'axios';
import { setAsyncInterval, clearAsyncInterval } from '../asyncIntervals.ts';

interface Versions {
  current: string|null;
  staging: string|null;
}

interface VersioningState {
  versions: Versions;
  versionInterval: number|null;
  updateAvailable: boolean;
}

export const useVersioningStore = defineStore('versioning', {
  state: (): VersioningState => ({
    versions: {
      current: null,
      staging: null
    },
    versionInterval: null,
    updateAvailable: false
  }),
  actions: {
    async checkVersion() {
      this.versions = {
        current: null,
        staging: null
      }
      // We need to go and fetch the mix manifest and compare this to the current version
      try {
        const response = await axios.get('/version');
        if (this.versions.current == null) {
          this.versions.current = JSON.stringify(response.data);
        }
        // Check if our current version matches the version
        if (!this.updateAvailable && this.versions.current != JSON.stringify(response.data)) {
          this.updateAvailable = true;
        }
        // Update our staging version to the latest versions
        if (this.updateAvailable && this.versions.staging != JSON.stringify(response.data)) {
          this.versions.staging = JSON.stringify(response.data);
        }
        this.checkInternalVersions();
      } catch(error: unknown) {
        if (isAxiosError(error) && axios.isCancel(error)) {
          console.log("Cancelled ", error.message);
        }
      };
    },
    checkInternalVersions(): void {
      if (this.versions.staging == null || this.versions.current == this.versions.staging) {
        this.updateAvailable = false;
        this.versions.staging = null;
      }
    },
    bootVersion(): void {
      this.versions.current = null;
      this.versions.staging = null;
      this.updateAvailable = false;
      this.versionInterval = setAsyncInterval(async () => await this.checkVersion(), 300000);
    },
    updateSite(): void {
      location.reload();
    }
  },
});
